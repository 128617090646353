// @TODO: remove this file if homepage is fully editable in contentful
export const advantages = {
  headline: 'Mit smava zu passenden Kreditangeboten',
  points: [
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'vh9g3jzzo9qx',
          },
        },
        id: '1VQnIOm1sbq4WFpxN3eH6S',
        type: 'Entry',
        createdAt: '2021-11-30T12:36:43.161Z',
        updatedAt: '2021-12-08T15:46:13.687Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'componentSellingpoint',
          },
        },
        locale: 'de-DE',
      },
      fields: {
        name: 'Zinsen',
        icon: {
          metadata: {
            tags: [],
          },
          sys: {
            space: {
              sys: {
                type: 'Link',
                linkType: 'Space',
                id: 'vh9g3jzzo9qx',
              },
            },
            id: '9nVaKZ3D0bt7yEwA13pI9',
            type: 'Asset',
            createdAt: '2021-11-30T12:35:08.843Z',
            updatedAt: '2021-12-08T15:46:13.376Z',
            environment: {
              sys: {
                id: 'master',
                type: 'Link',
                linkType: 'Environment',
              },
            },
            revision: 2,
            locale: 'de-DE',
          },
          fields: {
            title: 'interest.svg',
            description: '',
            file: {
              url: '/assets/icons/interest.svg',
              details: {
                size: 2277,
                image: {
                  width: 40,
                  height: 40,
                },
              },
              fileName: 'interest.svg',
              contentType: 'image/svg+xml',
            },
          },
        },
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'heading-3',
              content: [
                {
                  nodeType: 'text',
                  value: 'Günstige Zinsen',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value:
                    'Unvergleichliche Konditionen dank besonders günstiger Vereinbarungen mit unseren Partnerbanken.',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value: '',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
    },
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'vh9g3jzzo9qx',
          },
        },
        id: '2Ygz5Xdh0NqURJrkPNLTJn',
        type: 'Entry',
        createdAt: '2021-11-30T12:40:58.557Z',
        updatedAt: '2021-12-08T15:46:13.706Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'componentSellingpoint',
          },
        },
        locale: 'de-DE',
      },
      fields: {
        name: 'Schufaneutral',
        icon: {
          metadata: {
            tags: [],
          },
          sys: {
            space: {
              sys: {
                type: 'Link',
                linkType: 'Space',
                id: 'vh9g3jzzo9qx',
              },
            },
            id: '6QIRujoZV3ClX95nBFIhge',
            type: 'Asset',
            createdAt: '2021-11-30T12:40:26.021Z',
            updatedAt: '2021-12-08T15:46:13.383Z',
            environment: {
              sys: {
                id: 'master',
                type: 'Link',
                linkType: 'Environment',
              },
            },
            revision: 2,
            locale: 'de-DE',
          },
          fields: {
            title: 'neutral.svg',
            description: '',
            file: {
              url: '/assets/icons/neutral.svg',
              details: {
                size: 5607,
                image: {
                  width: 40,
                  height: 40,
                },
              },
              fileName: 'neutral.svg',
              contentType: 'image/svg+xml',
            },
          },
        },
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'heading-3',
              content: [
                {
                  nodeType: 'text',
                  value: 'SCHUFA-neutral',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value:
                    'Der smava Kreditvergleich hat keine negativen Auswirkungen auf Ihren SCHUFA-Score.',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
    },
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'vh9g3jzzo9qx',
          },
        },
        id: '5DUOHL0M2aGHYWVDa8qq02',
        type: 'Entry',
        createdAt: '2021-11-30T12:44:28.886Z',
        updatedAt: '2021-12-08T15:46:13.731Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'componentSellingpoint',
          },
        },
        locale: 'de-DE',
      },
      fields: {
        name: 'Kostenlos',
        icon: {
          metadata: {
            tags: [],
          },
          sys: {
            space: {
              sys: {
                type: 'Link',
                linkType: 'Space',
                id: 'vh9g3jzzo9qx',
              },
            },
            id: 'JHainmqLHsoEwQqQvEi8w',
            type: 'Asset',
            createdAt: '2021-11-30T12:44:12.937Z',
            updatedAt: '2021-12-08T15:46:13.388Z',
            environment: {
              sys: {
                id: 'master',
                type: 'Link',
                linkType: 'Environment',
              },
            },
            revision: 2,
            locale: 'de-DE',
          },
          fields: {
            title: 'free-cost.svg',
            description: '',
            file: {
              url: '/assets/icons/free.svg',
              details: {
                size: 3329,
                image: {
                  width: 40,
                  height: 40,
                },
              },
              fileName: 'free.svg',
              contentType: 'image/svg+xml',
            },
          },
        },
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'heading-3',
              content: [
                {
                  nodeType: 'text',
                  value: 'Kostenlos & unverbindlich',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value:
                    'Es warten keine versteckten Kosten auf Sie und Sie können die Anfrage jederzeit widerrufen.',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
    },
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'vh9g3jzzo9qx',
          },
        },
        id: '6fDzySV7WOrGytlUc9k68Z',
        type: 'Entry',
        createdAt: '2021-11-30T12:46:02.386Z',
        updatedAt: '2021-12-08T15:46:13.752Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'componentSellingpoint',
          },
        },
        locale: 'de-DE',
      },
      fields: {
        name: 'Datensicherheit',
        icon: {
          metadata: {
            tags: [],
          },
          sys: {
            space: {
              sys: {
                type: 'Link',
                linkType: 'Space',
                id: 'vh9g3jzzo9qx',
              },
            },
            id: '1ylBpxZkbqleKrZcSKkuvB',
            type: 'Asset',
            createdAt: '2021-11-30T12:45:39.980Z',
            updatedAt: '2021-12-08T15:46:13.396Z',
            environment: {
              sys: {
                id: 'master',
                type: 'Link',
                linkType: 'Environment',
              },
            },
            revision: 2,
            locale: 'de-DE',
          },
          fields: {
            title: 'data-safety',
            description: '',
            file: {
              url: '/assets/icons/data-safety.svg',
              details: {
                size: 2905,
                image: {
                  width: 40,
                  height: 40,
                },
              },
              fileName: 'data-safety.svg',
              contentType: 'image/svg+xml',
            },
          },
        },
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'heading-3',
              content: [
                {
                  nodeType: 'text',
                  value: 'Datensicherheit',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value:
                    'Für eine sichere Übermittlung Ihrer persönlichen Daten sorgen unsere strengen Datenschutzrichtlinien.',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
    },
  ],
  phone: {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'vh9g3jzzo9qx',
        },
      },
      id: '6PzUZGDLdqH7DRwTWsjrfw',
      type: 'Asset',
      createdAt: '2021-11-30T12:47:44.620Z',
      updatedAt: '2021-12-08T15:46:13.401Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 2,
      locale: 'de-DE',
    },
    fields: {
      title: 'fake-offer-list-phone.svg',
      description: '',
      file: {
        url: '/assets/icons/fake-offer-list.svg',
        details: {
          size: 131743,
          image: {
            width: 404,
            height: 575,
          },
        },
        fileName: 'fake-offer-list.svg',
        contentType: 'image/svg+xml',
      },
    },
  },
};
